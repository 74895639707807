export default {
  name: "NominatifDeposito",
  data() {
    return {
      form: {
        kodeProduk: "",
        dateFrom: "",
        dateTo: "",
        officeCode: "",
        userCode: "",
        isComaDelimited: false,
      },
      property: {
        listElement: {
          nominatif: {
            currentPage: 1,
            rows: 0,
            perPage: 10,
            message: "",
            downloading: false,
          },
        },
        animation: {
          nominatif: {
            isLoading: false,
          },
          isDownloadingFile: false,
        },
      },
      options: {
        officeId: [],
        officeListDataSoure: [],
        products: [],
        accountOfficer: [],
      },
    };
  },
  methods: {
    // appendDefaultOptionSelect() {
    //   const stringConditionDefaultOption = { value: "", text: "-- Pilih --" };
    //   this.options.export = [stringConditionDefaultOption];
    //   this.options.officeId = [{ text: "-- Pilih Semua --", value: "" }];
    //   this.options.officeListDataSoure = [
    //     { text: "-- Pilih Semua --", value: "" },
    //   ];
    //   this.options.products = [{ text: "-- Pilih Semua  --", value: "" }];
    //   this.options.accountOfficer = [{ text: "-- Pilih --", value: "" }];
    // },
    // helperGetOfficeDataForReference() {
    //   this.$store
    //     .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
    //       params: {
    //         officeName: "",
    //         page: 0,
    //       },
    //       url: "office",
    //     })
    //     .then((response) => {
    //       Promise.all(
    //         response.data.data.content.map((index) => {
    //           const value = index.officeId;
    //           const text = index.officeName;
    //           this.options.officeId.push({
    //             value,
    //             text,
    //           });
    //           this.options.officeListDataSoure.push({
    //             value,
    //             text,
    //           });
    //         })
    //       );
    //     });
    // },
    // getReferenceProducts() {
    //   this.$store
    //     .dispatch("GET_REFERENCE_FROM_MASTER_TRANSACTION", {
    //       params: {
    //         name: "",
    //         page: 0,
    //       },
    //       url: "deposit",
    //     })
    //     .then((response) => {
    //       Promise.all(
    //         response.data.data.content.map((index) => {
    //           const text = index.depositName;
    //           const value = index.depositId;
    //           this.options.products.push({
    //             value,
    //             text,
    //           });
    //         })
    //       );
    //     });
    // },
    // filterOfficeIdList(text) {
    //   console.log("triggered");
    //   this.options.officeId = this.options.officeListDataSoure.filter(
    //     (index) => {
    //       return (
    //         index.text
    //           .toString()
    //           .toLowerCase()
    //           .indexOf(text.toLowerCase()) >= 0
    //       );
    //     }
    //   );
    // },
    // async getReferenceUserAccountOfficer() {
    //   try {
    //     const resp = await this.$store.dispatch(
    //       "GET_REFERENCE_FROM_USER_MANAGEMENT",
    //       {
    //         params: {
    //           page: 0,
    //         },
    //         url: "v2/user/active-user",
    //       }
    //     );
    //     if (resp) {
    //       resp.data.data.content.map((index) => {
    //         const text = `${index.profileSurename} - ${index.userNik}`;
    //         const value = index.userCode;
    //         this.options.accountOfficer.push({ text, value });
    //       });
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    async onDownloadNominativeDepositoUs(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        isComaDelimiter: true,
      };
      console.log('test')
      this.property.animation.isDownloadingFile = true;
      try {
        console.log('test1')
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/nominative-deposit",
          payload: payload,
        });
        console.log("test download template", resp);
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Nominatif_Deposito.${
          fileType === "xls" ? "csv" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async onDownloadNominativeDepositoIdn(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        isComaDelimiter: false,
      };
      console.log('test')
      this.property.animation.isDownloadingFile = true;
      try {
        console.log('test1')
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/nominative-deposit",
          payload: payload,
        });
        console.log("test download template", resp);
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Nominatif_Deposito.${
          fileType === "xls" ? "csv" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
  },
  mounted() {
    // this.helperGetOfficeDataForReference();
    // this.getReferenceProducts();
    // this.appendDefaultOptionSelect();
    // this.getReferenceUserAccountOfficer();
  },
};
